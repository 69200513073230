import React from "react";
import "./Timeline.scss";
import activities from "./data"; // Import timeline data

const Timeline = () => (
  <ul className="timeline">
    {activities.timelineData.map((event, index) => (
      <li key={index}>
        <div className={index % 2 === 0 ? "direction-r" : "direction-l"}>
          <div className="flag-wrapper">
            <span className="flag">
              {event.link ? (
                <a href={event.link} target="_blank" rel="noopener noreferrer">
                  {event.title}
                </a>
              ) : (
                <>{event.title}</>
              )}
            </span>
            <span className="time-wrapper">
              <span className="time">{event.date}</span>
            </span>
          </div>
          <div className="desc">{event.description}</div>
        </div>
      </li>
    ))}
  </ul>
);

export default Timeline;

import React, { useContext, useState } from "react";
import "./Website.scss"; // Main stylesheet for your website
import activities from "./data"; // Your data file containing activities
import Timeline from "./Timeline"; // Timeline component
import WebsiteContext from "../../context/WebsiteContext";

const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentMonth = new Date().getMonth() + 1; // Get current month as number (1-12)
const currentYear = new Date().getFullYear();

const Website = () => {
  const { isPortfolioVisible, setIsPortfolioVisible } =
    useContext(WebsiteContext);
  const [activeNav, setActiveNav] = useState("aboutme");
  const [selectedMonth, setSelectedMonth] = useState(
    currentMonth.toString().padStart(2, "0")
  );

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // Filter books, movies, and albums by selected month and current year
  const filteredBooks = activities.books.filter((book) =>
    book.date.includes(`${currentYear}-${selectedMonth}`)
  );

  const filteredMovies = activities.movies.filter((movie) =>
    movie.date.includes(`${currentYear}-${selectedMonth}`)
  );

  const filteredAlbums = activities.albums.filter((album) =>
    album.date.includes(`${currentYear}-${selectedMonth}`)
  );

  // Function to format date in "Month Day, Title" format
  const formatBookDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-us", { month: "long" });
    const day = date.getDate();
    const title = activities.books.find(
      (book) => book.date === dateString
    )?.title;
    return `${month} ${day}, ${title}`;
  };

  const formatMovieDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-us", { month: "long" });
    const day = date.getDate();
    const title = activities.movies.find(
      (movie) => movie.date === dateString
    )?.title;
    return `${month} ${day}, ${title}`;
  };

  return (
    <div className="website">
      <header>
        <nav className="navbar">
          <ul>
            <li onClick={() => setIsPortfolioVisible(!isPortfolioVisible)}>
              Home
            </li>
            <li
              onClick={() => setActiveNav("aboutme")}
              className={`${activeNav == "aboutme" ? "active" : ""}`}
            >
              About Me
            </li>
            <li
              onClick={() => setActiveNav("project")}
              className={`${activeNav == "project" ? "active" : ""}`}
            >
              Projects
            </li>
            <li
              onClick={() => setActiveNav("timeline")}
              className={`${activeNav == "timeline" ? "active" : ""}`}
            >
              Timeline
            </li>
          </ul>
        </nav>
      </header>
      {activeNav == "timeline" && (
        <section className="timeline-section">
          <Timeline />
        </section>
      )}

      {activeNav == "project" && (
        <section className="projects">
          <div className="project-box">
            {activities.projects.map((project, index) => (
              <div className="project-card" key={index}>
                <h3>{project.name}</h3>
                {project.link ? (
                  <p>
                    <a
                      href={project.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Click to View Project here
                    </a>
                  </p>
                ) : (
                  ""
                )}
                <iframe
                  width="560"
                  height="315"
                  src={project.videoUrl}
                  title={project.name}
                  allow="accelerometer; autoplay=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                ></iframe>
              </div>
            ))}
          </div>
        </section>
      )}

      {activeNav == "aboutme" && (
        <section className="about">
          <div className="about-box">
            <div className="month-selector">
              <div className="selector">
                <label>Select Month: </label>
                <select value={selectedMonth} onChange={handleMonthChange}>
                  {months.slice(0, currentMonth).map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {selectedMonth && (
              <>
                <div className="monthly-activity">
                  <h3>
                    Books I Read in {months[parseInt(selectedMonth) - 1].label}{" "}
                    {currentYear}
                  </h3>
                  <ul>
                    {filteredBooks.length > 0 ? (
                      filteredBooks.map((book, index) => (
                        <li key={index}>{formatBookDate(book.date)}</li>
                      ))
                    ) : (
                      <li>No books read for this month.</li>
                    )}
                  </ul>
                </div>
                <div className="monthly-activity">
                  <h3>
                    Movies I Watched in{" "}
                    {months[parseInt(selectedMonth) - 1].label} {currentYear}
                  </h3>
                  <ul>
                    {filteredMovies.length > 0 ? (
                      [
                        ...new Set(filteredMovies.map((movie) => movie.date)),
                      ].map((dateString, index) => (
                        <li key={index}>{formatMovieDate(dateString)}</li>
                      ))
                    ) : (
                      <li>No movies watched for this month.</li>
                    )}
                  </ul>
                </div>
                <div className="monthly-activity">
                  <h3>
                    Albums {selectedMonth < currentMonth ? "I was" : "I'm"}{" "}
                    Listening To in {months[parseInt(selectedMonth) - 1].label}{" "}
                    {currentYear}
                  </h3>
                  <ul>
                    {filteredAlbums.length > 0 ? (
                      filteredAlbums.map((album, index) => (
                        <li key={index}>
                          {album.title} by {album.artist}
                        </li>
                      ))
                    ) : (
                      <li>No albums listened for this month.</li>
                    )}
                  </ul>
                </div>
              </>
            )}
          </div>
        </section>
      )}
    </div>
  );
};

export default Website;

import React, { createContext, useState } from "react";

const WebsiteContext = createContext();

export const WebsiteProvider = ({ children }) => {
  const [isPortfolioVisible, setIsPortfolioVisible] = useState(false);

  const contextValue = {
    isPortfolioVisible,
    setIsPortfolioVisible,
  };

  return (
    <WebsiteContext.Provider value={contextValue}>
      {children}
    </WebsiteContext.Provider>
  );
};

export default WebsiteContext;

import React from "react";
import "./NotFound.scss";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
export default function NotFoundPage() {
  return (
    <div className="notfound-container">
      <div className="loader"></div>
      <div className="goback">
        The Page is Lost in the Digital Wilderness...
        <Link to="/">
          g<IoArrowBackCircleOutline />
          back
        </Link>
      </div>
    </div>
  );
}

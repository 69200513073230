import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Linktree from "./pages/linktree/Linktree";
import NotFound from "./pages/notFound/NotFound";
import { WebsiteProvider } from "./context/WebsiteContext";
class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <WebsiteProvider>
            <Route path="/" exact component={Linktree} />
          </WebsiteProvider>
          <Route path="/not-found" component={NotFound} />
          <Route path="*" render={() => <Redirect to="/not-found" />} />
        </Switch>
      </Router>
    );
  }
}

export default App;
